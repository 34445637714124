<template>
  <div class="page">
    <div class="top">
      <div class="left" id="popover-button-sync">
        <div
          class="menu"
          v-for="(menu, index) in cates"
          :key="index"
          :class="index == active ? 'active' : ''"
          @click="changeMenu(index)"
        >
          {{ menu.collection_name }}
        </div>
      </div>
      <div v-show="show" class="pop">
        <div style="width: 450px; text-align: right;padding-right: 25px;height: 32px; line-height: 32px">
          <b-link @click="closePop" style="text-decoration: none">关闭</b-link>
        </div>
        <b-link class="pop-goods" v-for="(item, index) in cateList" :key="index" @click="toDetail(item.goods.product.id)">
          {{ item.goods ? item.goods.name : "" }}
        </b-link>
      </div>

      <div class="right">
        <img
          class="img"
          src="../../static/community/banner.png" 
          @error="defaultBanner"
        />
      </div>
    </div>
    <div class="tabs">
      <b-link
        class="tab"
        :class="index == menu ? 'tab-active' : ''"
        v-for="(tab, index) in menus"
        :key="index"
        @click="changeCate(index)"
        >{{ tab.name }}</b-link
      >
    </div>
    <div class="tables">
      <div class="item" v-for="(item, index) in goods" :key="index" @click="toDetail(item.product.id)">
        <img class="img" :src="$host + item.image" />
        <div class="price">
          <span style="font-size: 16px">￥</span
          >{{ item.product ? item.product.price : 0 }}
        </div>
        <div class="title">{{ item.name }}</div>
      </div>
    </div>
    <div style="width: 1200px; margin-top: 30px; text-align: center" v-show="count > limit">
      <b-pagination
        v-model="page"
        :total-rows="count"
        :per-page="limit"
      ></b-pagination>
    </div>
  </div>
</template>
<script>
export default { 
  data() {
    return {
      active: null,
      menus: [],
      childrens: [
        { text: "推荐" },
        { text: "微整形" },
        { text: "皮肤管理" },
        { text: "植发养发" },
        { text: "口腔类" },
        { text: "私密类" },
      ],
      cates: [],
      cateList: [],
      goods: [],
      banner: "",
      cate: "",
      show: false,
      page: 1,
      limit: 8,
      count: 0,
      menu: 0,
    };
  },
  watch: {
    page: function() {
      this.getGoods()
    }
  },
  methods: {
    changeMenu(index) {
      if (index == this.active) {
        this.show = false;
        this.active = null;
        return;
      }
      this.active = index;
      this.show = true;
      this.getPopGoods(this.cates[index].id);
    },
    closePop() {
      this.show = false;
      this.active = null;
    },
    getPopGoods(id) {
      let data = {
        collection_id: id,
      };
      this.$http.collgoodslist(data).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          this.cateList = data.items;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    changeCate(index) {
      if (index == this.menu) return;
      this.menu = index;
      this.getGoods();
      this.banner = this.$host + this.menus[index].image;
    },
    toDetail(id) {
      this.$router.push({
        path: "/goods_info?id="+id,
      });
    },
    getMenus() {
      this.$http.shop_catelist({}).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          this.menus = data;
          this.getGoods();
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    getCates() {
      this.$http.colllist({}).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          this.cates = data.items;
        } else {
          this.$store.commit("showToast", msg);
        }
      }); 
    },
    getGoods() {
      let data = {
        page: this.page,
        limit: this.limit,
        cat_id: this.menus[this.menu].id,
      };
      this.$http.shop_goodslist(data).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          this.goods = data.goods_list.items;
          this.count = data.goods_list.total;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    defaultBanner() {
      this.banner = require("../../static/community/banner.png");
    },
  },
  mounted() {
    this.getMenus();
    this.getCates();
  },
};
</script>
<style scoped lang="scss">
@import "./shop.scss";
</style>
